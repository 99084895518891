import { useTranslation } from 'react-i18next';
import { SignUpButtonRow } from 'views/landing/organisms/SignUpButtonRow';
import { SignUpCaption } from 'views/landing/organisms/SignUpCaption';

export const SignUpBoxBottom = ({ pathLang }: { pathLang: string }) => {
  const { t } = useTranslation('landing');
  const titleText = t('SignUpBox.title', { lng: pathLang });
  const descText = t('SignUpBox.desc', { lng: pathLang });

  return (
    <div
      className={`flex flex-col items-center mb-[80px]
        md:mb-[96px] lg:mb-[120px]`}
    >
      <h2
        dangerouslySetInnerHTML={{ __html: titleText }}
        className={`mb-[12px] text-headerL text-center
          md:mb-[16px] md:text-headerXL lg:text-header2XL`}
      />
      <p
        dangerouslySetInnerHTML={{ __html: descText }}
        className={`mb-[24px] text-center text-subheaderS
        md:mb-[32px] lg:mb-[40px] md:text-subheaderM lg:text-subheaderXL`}
      />
      <SignUpButtonRow pathLang={pathLang} />
      {/* caption: no credit card */}
      <SignUpCaption pathLang={pathLang} isTop={false} />
    </div>
  );
};
