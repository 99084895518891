import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import Image from 'next/image';
import { getLangPath } from 'utils/getLangPath';
import styles from 'components/styles/landing.module.css';

const OutputComparisonComponent = ({
  inputText,
  competitorName,
  competitorResult,
  engramResult,
}: {
  inputText: string;
  competitorName: string;
  competitorResult: React.ReactNode;
  engramResult: React.ReactNode;
}) => {
  const textBoxStyle = `p-[16px] rounded-md
  lg:px-[20px] lg:py-[24px] lg:rounded-lg`;

  return (
    <>
      {/* input example */}
      <div className={`${textBoxStyle} border border-outline mb-[16px]`}>
        <div className="text-headerXS md:text-headerS mb-[8px]">Input</div>
        <p
          className="break-keep text-bodyS1 md:text-bodyM1"
          dangerouslySetInnerHTML={{ __html: inputText }}
        />
      </div>
      {/* example comparison */}
      <div className={`${textBoxStyle} border border-primary`}>
        <div className="text-headerXS md:text-headerS mb-[8px]">{competitorName}</div>
        <div className="text-bodyS1 md:text-bodyM1">{competitorResult}</div>
        <div className={`w-full h-[12px] my-[12px] flex gap-[8px] flex-row
          items-center text-gray text-nowrap
          md:h-[16px]`}>
          <div className="w-[50%] h-[1px] bg-dividerDark" />
          <div className="text-header2XS md:text-headerXS">vs</div>
          <div className="w-[50%] h-[1px] bg-dividerDark" />
        </div>
        <div className="text-headerXS md:text-headerS mb-[8px]">Engram</div>
        <div className="text-bodyS1 md:text-bodyM1">{engramResult}</div>
      </div>
    </>
  );
};

const DescAndLink = ({
  headline,
  desc,
  href,
  linkBtnText,
}: {
  headline: string;
  desc: string;
  href: string;
  linkBtnText: string;
}) => {
  return (
    <div className="w-full flex flex-col items-center lg:w-fit lg:items-start">
      <h4
        className={`mt-[32px] mb-[8px] text-headerS
          md:mt-[40px] md:text-headerM lg:mt-0 lg:mb-[16px] lg:text-headerXL`}
      >
        {headline}
      </h4>
      <p
        className={`${styles.body} w-full text-center lg:w-[512px] lg:text-left`}
        dangerouslySetInnerHTML={{ __html: desc }}
      />
      <Link
        href={href}
        target="_blank"
        className={`mt-[16px] flex items-center text-buttonM2
          text-primary [&_img]:ml-[4px]
          md:mt-[16px] md:text-bodyL2 lg:mt-[32px] lg:text-button2XL2`}
      >
        {linkBtnText}
        <Image src="/icons/outline/i_arrow_right_primary.svg" width={24} height={24} alt="forward icon" />
      </Link>
    </div>
  );
};

export const CompetitorBox = ({ pathLang }: { pathLang: string }) => {
  const { t } = useTranslation('landing');

  const headline3Text = t('CompetitorBox.headline3', { lng: pathLang });
  const headline2Text = t('CompetitorBox.headline2', { lng: pathLang });

  const linkBtnText = t('CompetitorBox.LinkBtn', { lng: pathLang });

  const ex0HeadlineText = t('CompetitorBox.example-0-headline', { lng: pathLang });
  const ex0BodyText = t('CompetitorBox.example-0-body', { lng: pathLang });
  const ex0ComparisonName = t('CompetitorBox.example-0-comparisonBrand', { lng: pathLang });

  const toneBtn0 = t('CompetitorBox.toneBtn-0', { lng: pathLang });
  const toneBtn1 = t('CompetitorBox.toneBtn-1', { lng: pathLang });
  const toneBtn2 = t('CompetitorBox.toneBtn-2', { lng: pathLang });
  const toneBtn3 = t('CompetitorBox.toneBtn-3', { lng: pathLang });
  const toneBtn4 = t('CompetitorBox.toneBtn-4', { lng: pathLang });

  const ex1HeadlineText = t('CompetitorBox.example-1-headline', { lng: pathLang });
  const ex1BodyText = t('CompetitorBox.example-1-body', { lng: pathLang });
  const ex1ComparisonName = t('CompetitorBox.example-1-comparisonBrand', { lng: pathLang });

  const ex2HeadlineText = t('CompetitorBox.example-2-headline', { lng: pathLang });
  const ex2BodyText = t('CompetitorBox.example-2-body', { lng: pathLang });
  const ex2BeforeLang = t('CompetitorBox.transBeforeLang', { lng: pathLang });
  const ex2BeforeText = t('CompetitorBox.example-2-beforeText', { lng: pathLang });
  const ex2ComparisonName = t('CompetitorBox.example-2-comparisonBrand', { lng: pathLang });

  const comparisonStyle = 'text-[#34ba96] font-semibold';
  const engramStyle = 'text-primary font-semibold';
  const errorStyle = 'text-error line-through';

  const rowStyle = `w-full mt-[48px] flex flex-col justify-between items-center
  md:mt-[56px] lg:mt-[80px]`;

  const cardStyle = `w-full p-[16px] shadow-card rounded
  md:w-[416px] md:rounded-md lg:w-[432px] lg:p-[20px] lg:rounded-lg`;

  const paraToneBtnStyle = `h-[32px] px-[8px] flex items-center text-header2XS
  md:text-headerXS md:px-[12px] lg:text-headerS`;

  return (
    <div
      className="flex flex-col items-center mt-[80px] md:mt-[96px] lg:mt-[120px]"
    >
      {/* subheading */}
      <h3
        dangerouslySetInnerHTML={{ __html: headline3Text }}
        className={`${styles.h3} text-center mb-[12px] md:mb-[16px] lg:mb-[24px]`}
      />
      {/* heading */}
      <h2
        dangerouslySetInnerHTML={{ __html: headline2Text }}
        className={`${styles.h2} text-center`}
      />
      {/* Row - 0 */}
      <div className={`${rowStyle} lg:flex-row`}>
        {/* Card */}
        <div className={cardStyle}>
          <OutputComparisonComponent
            inputText="What do you plan for the left two days before going work?"
            competitorName={ex0ComparisonName}
            competitorResult={(
              <p className="break-keep">
                What do you plan for the
                {' '}
                <span className={errorStyle}>left</span>
                {' '}
                <span className={comparisonStyle}>next</span>
                {' '}
                two days before going
                {' '}
                <span className={comparisonStyle}>to</span>
                {' '}
                work?
              </p>
            )}
            engramResult={(
              <>
                What
                {' '}
                <span className={errorStyle}>do you plan</span>
                {' '}
                <span className={engramStyle}>are your plans</span>
                {' '}
                for the
                {' '}
                <span className={errorStyle}>left</span>
                {' '}
                <span className={engramStyle}>next</span>
                {' '}
                two days before going
                {' '}
                <span className={engramStyle}>to</span>
                {' '}
                work?
              </>
            )}
          />
        </div>
        {/* Right Text */}
        <DescAndLink
          headline={ex0HeadlineText}
          desc={ex0BodyText}
          href={getLangPath('/grammar-check', pathLang)}
          linkBtnText={linkBtnText}
        />
      </div>
      {/* Card Row - 1 */}
      <div className={`${rowStyle} lg:flex-row-reverse`}>
        {/* Card */}
        <div className={cardStyle}>
          <div className="flex flex-row justify-between items-center mb-[16px] lg:mb-[20px]">
            <div className={`${paraToneBtnStyle} bg-primaryHigh rounded-md`}>{toneBtn0}</div>
            <div className={paraToneBtnStyle}>{toneBtn1}</div>
            <div className={paraToneBtnStyle}>{toneBtn2}</div>
            <div className={paraToneBtnStyle}>{toneBtn3}</div>
            {pathLang === 'ko' && <div className={paraToneBtnStyle}>{toneBtn4}</div>}
            <div className={paraToneBtnStyle}>...</div>
          </div>
          <OutputComparisonComponent
            inputText="I have an appointment with my friend."
            competitorName={ex1ComparisonName}
            competitorResult={(
              <>
                My
                {' '}
                <span className={comparisonStyle}>pal and</span>
                {' '}
                I
                {' '}
                <span className={comparisonStyle}>had</span>
                {' '}
                a
                {' '}
                <span className={comparisonStyle}>meeting</span>
                .
              </>
            )}
            engramResult={(
              <>
                <span className={engramStyle}>I’m meeting up</span>
                {' '}
                with my friend.
              </>
            )}
          />
        </div>
        {/* Right Text */}
        <DescAndLink
          headline={ex1HeadlineText}
          desc={ex1BodyText}
          href={getLangPath('/paraphrase', pathLang)}
          linkBtnText={linkBtnText}
        />
      </div>
      {/* Card Row - 2 */}
      <div className={`${rowStyle} lg:flex-row`}>
        {/* Card */}
        <div className={cardStyle}>
          {/* Translate Card Top Row */}
          <div
            className={`px-[16px] mb-[16px] flex fle-row justify-between
              items-center text-header2XS
              md:text-headerXS lg:px-[20px] lg:mb-[20px] lg:text-headerS`}>
            {ex2BeforeLang}
            <Image src="/icons/outline/i_arrow_right.svg" width={24} height={24} alt="forward icon" />
            {' '}
            English
          </div>
          <OutputComparisonComponent
            inputText={ex2BeforeText}
            competitorName={ex2ComparisonName}
            competitorResult={
              pathLang === 'ja' ? (
                <>I am proud of my ability to lead people together.</>
              ) : pathLang === 'cn' ? (
                <>This novel makes people have endless aftertaste after reading it.</>
              ) : pathLang === 'es' ? (
                <>Stand on the edge of the chair with your feet flat on the floor.</>
              ) : (
                <>It would be good to be able to determine the direction of data collection in the future.</>
              )
            }
            engramResult={
              pathLang === 'ja' ? (
                <>I have confidence in my ability to lead and manage people.</>
              ) : pathLang === 'cn' ? (
                <>This novel leaves a lasting impression on readers.</>
              ) : pathLang === 'es' ? (
                <>Sit at the edge of the chair with your feet on the ground. </>
              ) : (
                <>It would be great if we could determine the direction of data collection in the future.</>
              )
            }
          />
        </div>
        {/* Right Text */}
        <DescAndLink
          headline={ex2HeadlineText}
          desc={ex2BodyText}
          href={getLangPath('/translate', pathLang)}
          linkBtnText={linkBtnText}
        />
      </div>
    </div>
  );
};
