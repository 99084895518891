import styles from 'components/styles/landing.module.css';
import { SignUpButtonRow } from 'views/landing/organisms/SignUpButtonRow';
import { SignUpCaption } from 'views/landing/organisms/SignUpCaption';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function SignUpBoxTop({ pathLang }: { pathLang: string }) {
  const { t } = useTranslation('landing');

  const headingText = t('SimulationBox.heading', { lng: pathLang });
  const subheadingText = t('SimulationBox.subHeading', { lng: pathLang });
  return (
    <div
      className={`w-full mb-[56px] flex flex-col items-center
          md:mb-[64px] lg:w-fit lg:mb-0 lg:items-start`}
    >
      {/* Heading Text */}
      <h1
        dangerouslySetInnerHTML={{ __html: headingText }}
        className={`${styles.h1} mb-[12px]
          md:mb-[16px] lg:mb-[20px] lg:!text-left`}
      />
      {/* Subheading Text */}
      <h4
        dangerouslySetInnerHTML={{
          __html: subheadingText.replaceAll('<li>', '<li><span class="bullet">•</span>'),
        }}
        className={`mb-[24px] text-subheaderS text-center
          [&_li]:block [&_.bullet]:inline-block [&_.bullet]:mr-[8px]
          md:mb-[32px] md:text-subheaderM
          lg:mb-[40px] lg:text-subheaderL lg:text-left lg:[&_li]:flex
          lg:[&_.bullet]:mr-[10px] lg:[&_.bullet]:h-full lg:[&_.bullet]:inline-block`}
      />
      {/* CTA Sign-up Button Row */}
      <SignUpButtonRow pathLang={pathLang} />
      {/* caption: no credit card */}
      <SignUpCaption pathLang={pathLang} isTop />
    </div>
  );
}
