import { Player } from '@lottiefiles/react-lottie-player';
import React, { useEffect, useState } from 'react';

export function VideoBox({ pathLang }: { pathLang: string }) {
  const [browser, setBrowser] = useState('');

  useEffect(() => {
    const { userAgent } = navigator;
    if (userAgent.includes('NAVER(inapp;')) {
      setBrowser('naver');
    } else if (userAgent.includes('IE')) {
      setBrowser('ie');
    } else if (userAgent.includes('Edg/')) {
      setBrowser('edge');
    } else if (userAgent.includes('OPR/') || userAgent.includes('Opera')) {
      setBrowser('opera');
    } else if (userAgent.includes('Firefox/') || userAgent.includes('Gecko/')) {
      setBrowser('firefox');
    } else if (userAgent.includes('Whale/')) {
      setBrowser('whale');
    } else if (userAgent.includes('Chrome/') || userAgent.includes('CriOS/')) {
      setBrowser('chrome');
    } else if (userAgent.includes('iPhone') || userAgent.includes('Macintosh') || userAgent.includes('iPad')) {
      setBrowser('safari');
    }
  }, []);

  return (
    <div
      className="w-[312px] h-[312px] rounded shadow-cardLg overflow-hidden
        md:w-[360px] md:h-[360px] md:rounded-md
        lg:w-[480px] lg:h-[480px] lg:rounded-lg"
    >
      {browser === 'safari' ? (
        <video autoPlay loop playsInline muted>
          <source
            src={`/motions/landing_${pathLang}.mp4`}
            type="video/mp4"
          />
        </video>
      ) : (
        <Player
          autoplay
          loop
          src={`/motions/landing_${pathLang}.json`}
        />
      )}
    </div>
  );
}
