import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import styles from 'components/styles/landing.module.css';

export const TrustedBox = ({ pathLang }: { pathLang: string }) => {
  const { t } = useTranslation('landing');

  const headingText = t('TrustedBox.heading', { lng: pathLang });
  const imageSrc = `/images/landing2/ico-trusted-logos${
    pathLang === 'ko' ? '' : (pathLang === 'ja' || pathLang === 'cn') ? '-ja' : '-en'
  }.png`;

  return (
    <div className="w-full flex flex-col mt-[80px] md:mt-[96px] lg:mt-[120px]">
      <h2
        dangerouslySetInnerHTML={{ __html: headingText }}
        className={`${styles.h2} text-center w-full`}
      />
      <Image
        src={imageSrc}
        alt="logo of Engram's avid users."
        width={1064}
        height={140}
        draggable={false}
        className="mt-[24px] md:mt-[32px] lg:mt-[48px]"
      />
    </div>
  );
};
