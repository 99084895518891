import { LandingLayout } from 'components/layouts';
import { useRouter } from 'next/router';
import { getPathLang } from 'utils/getPathLang';
import { TrustedBox } from 'views/landing/organisms/TrustedBox';
import { BlogBox } from 'views/landing/organisms/BlogBox';
import { VideoBox } from 'views/landing/organisms/VideoBox';
import { SignUpBoxTop } from 'views/landing/organisms/SignUpBoxTop';
import { CompetitorBox } from 'views/landing/organisms/CompetitorBox';
import { AllInOneBox } from 'views/landing/organisms/AllInOneBox';
import { ChatGPTAltBox } from 'views/landing/organisms/ChatGPTAltBox';
import { UserReviewBox } from 'views/landing/organisms/UserReviewBox';
import { FAQBox } from 'views/landing/organisms/FAQBox';
import { SignUpBoxBottom } from 'views/landing/organisms/SignUpBoxBottom';
import styles from 'components/styles/landing.module.css';

export const Landing = () => {
  const router = useRouter();
  const pathLang = getPathLang(router.pathname);

  return (
    <LandingLayout>
      <div className={`${styles.mainContainer} ${pathLang === 'ko' ? 'break-keep' : 'break-normal'}`}>
        <div className={`${styles.mainWrap}`}>
          {/* Header */}
          <div className="flex flex-col justify-between items-center lg:flex-row">
            {/* Text & Buttons */}
            <SignUpBoxTop pathLang={pathLang} />
            {/* Video */}
            <VideoBox pathLang={pathLang} />
          </div>
          <TrustedBox pathLang={pathLang} />
          <CompetitorBox pathLang={pathLang} />
          {/* entire area */}
          <div className="w-[100vw] ml-[calc(50%-50vw)]">
            <AllInOneBox pathLang={pathLang} />
          </div>
          <ChatGPTAltBox pathLang={pathLang} />
          {/* entire area */}
          <div className="w-[100vw] ml-[calc(50%-50vw)]">
            <UserReviewBox pathLang={pathLang} />
          </div>
          <FAQBox pathLang={pathLang} />
          <BlogBox pathLang={pathLang} />
          <SignUpBoxBottom pathLang={pathLang} />
        </div>
      </div>
    </LandingLayout>
  );
};
