import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { SocialLoginButton } from 'components/buttons';

export const SignUpButtonRow = ({ pathLang }: {pathLang: string;}) => {
  const { t } = useTranslation('landing');
  const [, setIsShowInAppBrowserNotice] = useState(false);
  const ctaText = t('SimulationBox.CTA', { lng: pathLang });
  const ctaGoogleText = t('SimulationBox.googleCta', { lng: pathLang });
  const ctaKakaoText = t('SimulationBox.kakaoCta', { lng: pathLang });
  const [isNaver, setIsNaver] = useState(false);

  // const router = useRouter();
  // const clickCTA = async () => {
  //   const cookies = new Cookies();
  //   const trial_uid = cookies.get('trial_uid');
  //   axios.post(
  //     `${process.env.API_HOST}/trial/v7/click/${router.pathname.endsWith('grammar-check') ? 0 : 1}/${trial_uid}`,
  //   );
  //   await router.push('/auth/sign-up');
  // };
  useEffect(() => {
    const { userAgent } = navigator;
    setIsNaver(userAgent.includes('NAVER(inapp;'));
  }, []);

  return (
    <div
      className={`w-full flex flex-col-reverse items-center justify-center
      md:w-[416px] md:flex-row md:justify-between
      lg:w-[464px]`}
    >
      <Link
        href="/auth/sign-up"
        className={`btn0 btn-solid w-full h-[40px] 
          md:w-[200px]
          lg:w-[224px] lg:h-[48px]`}
      >
        {ctaText}
      </Link>
      <SocialLoginButton
        isSignUp
        type={isNaver ? 'Kakao' : 'Google'}
        customLabel={isNaver ? ctaKakaoText : ctaGoogleText}
        setIsShowInAppBrowserNotice={setIsShowInAppBrowserNotice}
        isLandingPage
        marginBottomMb={12}
      />
    </div>
  );
};
