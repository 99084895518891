import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import styles from 'components/styles/landing.module.css';

export const ChatGPTAltBox = ({ pathLang }: {pathLang: string}) => {
  const { t } = useTranslation('landing');

  const headline3Text = t('ChatGPTAltBox.headline2', { lng: pathLang });
  const headline2Text = t('ChatGPTAltBox.headline1', { lng: pathLang });

  const info0TitleText = t('ChatGPTAltBox.case-0-title', { lng: pathLang });
  const info0TitleBody = t('ChatGPTAltBox.case-0-body', { lng: pathLang });
  const info1TitleText = t('ChatGPTAltBox.case-1-title', { lng: pathLang });
  const info1TitleBody = t('ChatGPTAltBox.case-1-body', { lng: pathLang });

  const cardItemStyle = `w-full py-[16px] pr-[10px] pl-[16px] flex flex-col
  items-start rounded shadow-card bg-surface
  md:w-[452px] md:p-[16px] md:rounded-md
  lg:w-[512px] lg:p-[20px] lg:rounded-lg`;

  const imageStyle = `w-[48px] h-[48px]
  md:w-[56px] md:h-[56px] lg:w-[64px] lg:h-[64px]`;

  const cardTitleRowStyle = `mt-[24px] mb-[12px] flex flex-row items-center
  md:mt-[24px] md:mb-[12px]
  lg:mt-[32px] lg:mb-[16px]`;

  const numStyle = `w-[16px] h-[16px] mr-[8px] flex justify-center items-center
  text-buttonsXS2 text-surface bg-primary rounded-[24px]
  md:w-[20px] md:h-[20px] md:text-buttonsS2 lg:w-[24px] lg:h-[24px] lg:text-buttonsM2`;

  const cardTitleStyle = 'text-headerS md:text-headerM lg:text-headerXL'

  return (
    <div
      className={`py-[80px] flex flex-col items-center
        md:py-[96px] lg:py-[120px]`}
      >
      <h3 className={`${styles.h3} mb-[12px] lg:mb-[20px]`}>{headline3Text}</h3>
      <h2
        dangerouslySetInnerHTML={{ __html: headline2Text }}
        className={`${styles.h2} text-center mb-[40px] md:mb-[48px] lg:mb-[80px]`}
      />
      {/* Info Card - 0 */}
      <div className={cardItemStyle}>
        <Image
          src="/images/landing2/ill-time.svg"
          alt=""
          width={64}
          height={64}
          className={imageStyle}
        />
        <div className={cardTitleRowStyle}>
          <div className={numStyle}>1</div>
          <div className={cardTitleStyle}>{info0TitleText}</div>
        </div>
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: info0TitleBody }}
        />
      </div>
      {/* Info Card - 1 */}
      <div className={`${cardItemStyle} mt-[24px] lg:mt-[40px]`}>
        <Image
          src="/images/landing2/ill-thumbup.svg"
          alt=""
          width={64}
          height={64}
          className={imageStyle}
        />
        <div className={cardTitleRowStyle}>
          <div className={numStyle}>2</div>
          <div className={cardTitleStyle}>{info1TitleText}</div>
        </div>
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: info1TitleBody }}
        />
      </div>
    </div>
  );
};
