import { useTranslation } from 'react-i18next';
import Image from 'next/image';

export const SignUpCaption = ({ pathLang, isTop }: {pathLang: string; isTop?: boolean;}) => {
  const { t } = useTranslation('landing');
  const caption = t('SimulationBox.caption', { lng: pathLang });

  return (
    <div
      className={`mt-[12px] flex flex-row items-center
      md:mt-[16px]
      lg:mt-[20px] ${isTop && 'lg:justify-start'}
      `}
    >
      <Image src="/icons/outline/i_check.svg" priority width={16} height={16} alt="check icon" />
      <p className="ml-[4px] text-captionS md:text-captionM lg:text-captionL">
        {caption}
      </p>
    </div>
  );
};
